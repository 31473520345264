<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<planning_nav :site="site" :is_planning_complete="is_planning_complete" />

				<div class="w-100 h-100 position-relative py-4 overflow-auto">

					<div class="container h-100">

						<div class="row h-100 justify-content-center mb-4">

							<div class="col-12 col-md-10 col-lg-8 col-xxl-6">

								<div v-if="filtered_pages.length" class="card mb-4 bg-primary text-white border-0 position-relative">

									<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-between">

										<div class="me-2">
											<div class="mx-auto overflow-hidden" style="width: 200px; height: 200px; margin-top: -30px">
												<img src="@/assets/img/bella.png" class="img-fluid">
											</div>
										</div>

										<div class="flex-grow-1">
											<p class="h5" v-if="!loading">Would you like me to generate content for all empty pages?</p>
											<p class="h5" v-else>
												This should take me around <countdown :time="120 * filtered_pages.length" :start="loading" />. Don't leave the page while I'm generating.
											</p>

											<div class="mb-2">
												<btn_submit 
													label="Yes, generate content" 
													:icon_after="true" 
													:loading="loading" 
													v-on:click="generate_content( 0 )"
												/>
											</div>
										</div>

									</div>

								</div>
								
								<div v-if="pages.length" class="card mb-4">

									<div v-for="( page, page_key ) in pages" :key="'page-' + page.ID" class="card-body border-bottom p-4 d-flex align-items-center justify-content-between bg-opacity-10" :class="strip_tags( page.original_content ).length ? 'bg-success' : ''">

										<div>
											<p class="fw-bold mb-2">
												{{ page.post_title }}
											</p>

											<div class="d-flex align-items-center small">
												<router-link :to="{ name: 'site.planning.page', params: { page_id: page.ID } }">
													Edit content
												</router-link>
												<span class="text-muted ms-3">({{ page.original_content ? strip_tags( page.original_content ).length : 0 }} characters)</span>
											</div>
										</div>

										<div>

											<div v-if="generating == page_key">
												<span class="spinner-border" role="status">
													<span class="visually-hidden">Loading...</span>
												</span>
											</div>
											<span v-else class="material-icons-outlined" :class="strip_tags( page.original_content ).length ? 'text-success' : 'text-warning'">{{ strip_tags( page.original_content ).length ? 'check_circle' : 'info' }}</span>
										</div>

									</div>			

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</div>

		<share_modal :is_plan="true" />

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import planning_nav from '@/components/planning_nav'
import btn_submit from '@/components/btn_submit'
import countdown from '@/components/countdown'
import share_modal from '@/components/share_modal'
import page_service from '@/services/page_service'

export default {
	name: 'site.planning.content',

	props: {
		modelValue: [Object],
		workspace: [Object],
		is_planning_complete: Boolean,
		is_design_complete: Boolean,
		is_build_complete: Boolean,
		is_launch_complete: Boolean
	},

	emits: [
		'update:modelValue',
	],

	components: {
		planning_nav,
		btn_submit,
		countdown,
		share_modal
	},

	data() {
		return {
			loading: false,
			site: null,
			success: null,
			error: {},
			pages: [],
			generating: null
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		filtered_pages()
		{
			if ( !this.pages ) {
				return []
			}
			return this.pages.filter( (ar) => {
				var content = this.strip_tags( ar.original_content )
				return ( !content.length )
			} )
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_pages()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, '?flatten=true' ).then(( response ) => {

				this.pages = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		generate_content(key)
		{
			this.generating = key

			this.loading = true

			this.reset_error()

			var page = this.pages[this.generating]

			if ( this.strip_tags( page.original_content ).length > 0 ) {
				var new_key = this.generating + 1
				if ( this.pages[new_key] ) {
					return this.generate_content( new_key )
				} else {
					this.loading = false
					this.generating = null
				}
			} else {
				page_service.generate_content( this.$route.params.workspace_id, this.$route.params.site_id, { page: page, description: null, is_save: true } ).then(( response ) => {

					this.pages = response.data

					var new_key = this.generating + 1

					if ( this.pages[new_key] ) {
						this.generate_content((this.generating + 1))
					} else {
						this.generating = null
						this.loading = false
					}

					this.version++

				}).catch( ( error ) => {

					this.error = error
					this.generating = null

				})
			}
		},

		strip_tags( html )
		{
			if ( html == null ) {
				return '';
			}
			const doc = new DOMParser().parseFromString(html, 'text/html');
			return doc.body.textContent || '';
		}
	}
}
</script>